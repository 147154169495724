<template>
  <div class="flex z-10 inset-0 overflow-y-auto bg-gray-500 bg-opacity-75 h-full items-center justify-center" @click.self="close">
    <div class="flex z-20">
      <div class="max-w-3xl mx-auto bg-primary p-5 rounded-md flex flex-col text-white">
        <h1 class="text-xl">{{ $t("Advanced Search") }}</h1>
        <div class="flex mt-5 px-5 items-center">
          <span class="w-4/12">{{ $t("Text in note") }}</span>
          <input
            v-model="search.search"
            type="text"
            name="betrag"
            tabindex="30"
            required
            class="w-8/12 my-2 p-1 bg-white border border-gray-400 hover:border-gray-500 px-6 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-secondary"
          />
        </div>
        <div class="flex mt-5 px-5 items-center text-secondary justify-between">
          <div>
            <span class="text-white mr-1">{{ $t("From Date") }}</span>
            <datepicker
              class="modal-cs-width text-secondary bg-white rounded-md border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 shadow leading-tight focus:outline-none focus:shadow-outline"
              :inputFormat="'dd-MM-yyyy'"
              v-model="start"
              :locale="lan"
            ></datepicker>
          </div>
          <div>
            <span class="text-white mr-1">{{ $t("To Date") }}</span>
            <div class="flex">
              <datepicker
                class="modal-cs-width text-secondary bg-white rounded-md border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 shadow leading-tight focus:outline-none focus:shadow-outline"
                :inputFormat="'dd-MM-yyyy'"
                v-model="end"
                :locale="lan"
              ></datepicker>
            </div>
          </div>
        </div>
        <div class="flex mt-5 px-5 items-center">
          <span class="w-4/12">{{ $t("Person") }}</span>
          <select v-model="search.person_id" class="w-8/12 my-2 p-1 bg-white border border-gray-400 hover:border-gray-500 px-2 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-secondary capitalize">
            <option class="capitalize" v-for="(item, i) in persons" :key="i" :value="item.id">
              {{ item.name == "together" ? $t(item.name) : item.name }}
            </option>
          </select>
        </div>

        <div class="flex mt-5 px-5 items-center">
          <span class="text-sm text-white w-4/12">{{ $t("Account") }}</span>
          <select v-model="search.account_id" class="w-8/12 my-2 p-1 bg-white border border-gray-400 hover:border-gray-500 px-6 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-secondary capitalize">
            <option v-for="(item, i) in accountList" :key="i" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
        <!-- <div class="flex mt-5 px-5 items-center">
          <span class="text-sm text-white w-4/12">{{ $t("Categroy") }}</span>
          <select v-model="search.category_id" class="w-8/12 my-2 p-1 bg-white border border-gray-400 hover:border-gray-500 px-6 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-secondary capitalize">
            <option v-for="(item, i) in categories" :key="i" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div> -->
        <div class="flex mt-5 px-5 items-center">
          <div class="block relative w-full mb-2">
            <span class="text-sm text-white w-4/12">{{ $t("Category") }}</span>
            <select
              id="select-state"
              placeholder="Pick a state..."
              v-model="search.category_id"
              class="w-full mb-2 p-1 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              required
            >
              <optgroup v-for="(cat, i) in categories" :key="i" :label="$t(cat.name)">
                <option v-for="(item, i) in cat.children.filter((cat) => cat.active)" :key="i" :value="item.id">
                  {{ $t(item.name) }}
                </option>
              </optgroup>
            </select>
          </div>
        </div>

        <div class="flex mt-5 px-5 items-center">
          <span class="w-4/12">{{ $t("Amount") }}</span>
          <input
            v-model="search.amount"
            type="text"
            name="betrag"
            tabindex="30"
            required
            class="w-8/12 my-2 p-1 bg-white border border-gray-400 hover:border-gray-500 px-6 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-secondary"
          />
        </div>
        <div class="flex justify-end px-5 mt-2">
          <button @click="getMovementList" class="bg-secondary text-white px-6 py-2 w-4/12 rounded-md">
            {{ $t("Search") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "../../node_modules/vue3-datepicker";
import { de, it } from "date-fns/locale";

export default {
  components: { Datepicker },
  props: ["dateSource"],
  data() {
    return {
      start: new Date(`${this.dateSource.getFullYear()}/${this.dateSource.getMonth() + 1}/01`),
      end: new Date(),
      search: {
        person_id: null,
      },
      lan: this.$i18n.locale == "de" ? de : it,
    };
  },
  watch: {},
  computed: {
    persons() {
      let list = [{ name: "together", id: "common" }];
      if (this.$store.state.User.persons.data) {
        this.$store.state.User.persons.data.map((person) => {
          list.push({
            name: person.name,
            id: person.id,
          });
        });
      }

      return list;
    },
    accountList() {
      return this.$store.state.accounts.accountList.data || [];
    },
    categories() {
      let list = [];
      this.$store.state.User.categories.forEach((m) => (list = list.concat(m.children)));
      return list;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    getMovementList() {
      this.search.start = `${this.start.getFullYear()}-${this.start.getMonth() + 1}-${this.start.getDate()}`;
      this.search.end = `${this.end.getFullYear()}-${this.end.getMonth() + 1}-${this.end.getDate()}`;
      this.$store.dispatch("movement/getMovementList", this.search).then(() => {
        this.$emit("closeAndFilter", {
          start: this.search.start,
          end: this.search.end,
        });
        this.$store.dispatch("movement/setSearchFilters", this.search);
      });
    },
  },
  mounted() {
    this.$store.dispatch("User/getPersons");
    this.$store.dispatch("accounts/getAccountList", { count: 1000 });
    this.$store.dispatch("User/GetCategory");
  },
};
</script>
